import { usePage } from "@/hooks/ui/use-pages.hook";
import { styled, Typography } from "@mui/material";
import { useRouter } from "next/router";
import { memo } from "react";

interface Props {
  name: string;
  address: string;
  tel: string;
}
export const Organization: React.FC<Props> = memo((props) => {
  const router = useRouter();
  const page = usePage();

  return (
    <Wrapper>
      <Name onClick={() => router.push(page.admin.pathname)}>{props.name}</Name>
      <Address>{props.address}</Address>
      <Tel>{props.tel}</Tel>
    </Wrapper>
  );
});

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
}));

const Name = styled(Typography)(({ theme }) => ({
  fontSize: theme.fontSize.xxl,
  whiteSpace: "nowrap",
}));

const Address = styled(Typography)(({ theme }) => ({}));

const Tel = styled(Typography)(({ theme }) => ({}));
