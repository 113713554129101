import { Fade, styled, Container as MuiContainer } from "@mui/material";
import Image from "next/image";
import { memo, PropsWithChildren, useEffect, useState } from "react";

interface Props {
  backgroundFixedImageSrc?: string;
  Header?: JSX.Element;
  Footer?: JSX.Element;
}
export const MainLayout: React.FC<PropsWithChildren<Props>> = memo((props) => {
  const [windowHeight, setWindowHeight] = useState<string>("100vh");

  useEffect(() => {
    if (window.innerHeight) setWindowHeight(`${window.innerHeight}px`);
  }, []);

  return (
    <Wrapper src={props.backgroundFixedImageSrc}>
      {props.backgroundFixedImageSrc && (
        <StyledImage
          src={props.backgroundFixedImageSrc}
          width={1000}
          height={1000}
          alt="background"
          priority
          style={{ height: windowHeight }}
        />
      )}

      {props.Header && (
        <HeaderWrapper>
          <HeaderContainer maxWidth="lg">{props.Header}</HeaderContainer>
        </HeaderWrapper>
      )}

      <Fade in={true}>
        <Main>{props.children}</Main>
      </Fade>

      {props.Footer && (
        <FooterWrapper>
          <FooterContainer>{props.Footer}</FooterContainer>
        </FooterWrapper>
      )}
    </Wrapper>
  );
});

const StyledImage = styled(Image)(({ theme }) => ({
  position: "fixed",
  left: 0,
  top: 0,
  zIndex: -1,
  width: "100%",
  objectFit: "cover",
}));

const Wrapper = styled("div")<{ src?: string }>(({ theme, src }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  position: "relative",
}));

const Main = styled("main")(({ theme }) => {
  const height = window.innerHeight;

  return {
    flexGrow: 1,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    minHeight: `calc(${height}px - 94px)`,
    [theme.breakpoints.down("sm")]: {
      minHeight: `calc(${height}px - 64px)`,
    },
  };
});

const HeaderWrapper = styled("div")(({ theme }) => ({
  position: "sticky",
  top: 0,
  width: "100%",
  backgroundColor: "#0C1115",
  color: "white !important",
  zIndex: theme.zIndex.appBar,
}));

const FooterWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  color: theme.color.grey[400],
  backgroundColor: "#0c1115",
}));

const HeaderContainer = styled(MuiContainer)(({ theme }) => ({}));

const FooterContainer = styled(MuiContainer)(({ theme }) => ({}));
