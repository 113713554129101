import { usePage } from "@/hooks/ui/use-pages.hook";
import { ButtonBase, styled } from "@mui/material";
import Image from "next/image";
import { useRouter } from "next/router";
import { memo } from "react";

export const BrandLogo: React.FC = memo((props) => {
  const page = usePage();
  const router = useRouter();

  return (
    <StyledButtonBase onClick={() => router.push(page.top.pathname)}>
      <Image
        src={`${process.env.NEXT_PUBLIC_AWS_S3_BUCKET_URL}/images/_brand/icon-white.png`}
        alt="logo"
        width={1000}
        height={1000}
        priority
        style={{ width: "auto", height: "100%" }}
      />
    </StyledButtonBase>
  );
});

const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
  height: "100%",
  borderRadius: theme.shape.borderRadius,
}));
