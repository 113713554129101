import { styled, Typography, TypographyProps } from "@mui/material";
import { memo } from "react";

interface CopyrightProps {
  name: string;
  textAlign?: "left" | "center" | "right";
}
export const Copyright: React.FC<CopyrightProps> = memo((props) => {
  const year = new Date().getFullYear();

  return (
    <Text
      textAlign={props.textAlign}
    >{`© ${year} ${props.name}, Inc. All rights reserved.`}</Text>
  );
});

const Text = styled((props: TypographyProps) => <Typography {...props} />)<{
  textAlign?: "left" | "center" | "right";
}>(({ theme, textAlign }) => ({
  textAlign,
  fontSize: theme.fontSize.xs,
}));
