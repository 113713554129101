import { usePage } from "@/hooks/ui/use-pages.hook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "@mui/material";
import { styled } from "@mui/material";
import Image from "next/image";
import { useRouter } from "next/router";
import { memo, MouseEvent, useCallback } from "react";
import { Organization } from "../brand/organization";
import { Copyright } from "@/components/atoms/copy-right";

interface Item {
  label: string;
  value: string;
  pathname: string;
}

export const MainFooter: React.FC = memo((props) => {
  const page = usePage();
  const router = useRouter();

  const list: Item[] = [
    {
      label: "トップ",
      value: page.top.pathname,
      pathname: page.top.pathname,
    },
    {
      label: page.notifications.title,
      value: page.notifications.pathname,
      pathname: page.notifications.pathname,
    },
    {
      label: page.menu.title,
      value: page.menu.pathname,
      pathname: page.menu.pathname,
    },
    {
      label: "アクセス",
      value: "access",
      pathname: `${page.top.pathname}#access`,
    },
    {
      label: "お問い合せ",
      value: "contact",
      pathname: `${page.top.pathname}#contact`,
    },
  ];

  console.log(
    `${process.env.NEXT_PUBLIC_AWS_S3_BUCKET_URL}/images/_brand/uber.png`
  );

  return (
    <Wrapper>
      <Detail>
        <OrganizationWrapper>
          <Organization
            name="Aun Kitchen"
            address="〒145-0064 東京都大田区上池台1-45-15"
            tel="TEL: 03-6425-6588"
          />
        </OrganizationWrapper>

        <Links>
          <InnerLinks>
            {list.map((item, index) => (
              <StyledLink key={index} href={item.pathname}>
                {item.label}
              </StyledLink>
            ))}
          </InnerLinks>

          <ExternalLinks>
            <StyledLink
              target="_blank"
              href="https://www.instagram.com/aun.kitchen"
            >
              <BrandIcon icon={["fab", "instagram"]} />
              Instagram
            </StyledLink>

            <StyledLink
              target="_blank"
              href="https://www.ubereats.com/jp/store/%E3%82%A2%E3%82%A6%E3%83%B3%E3%82%AD%E3%83%83%E3%83%81%E3%83%B3-aunkitchen/_Aawx1BrTPuTnKBKR_HLFA?diningMode=DELIVERY&ps=1"
            >
              <UberIcon
                src={`${process.env.NEXT_PUBLIC_AWS_S3_BUCKET_URL}/images/_brand/uber.png`}
                width={100}
                height={100}
                priority
                alt="uber"
              />
              Uber Eats
            </StyledLink>

            <StyledLink
              target="_blank"
              href="https://sp.demae-can.com/shop/menu/3303291"
            >
              <UberIcon
                src={`${process.env.NEXT_PUBLIC_AWS_S3_BUCKET_URL}/images/_brand/demae-can.png`}
                width={100}
                height={100}
                priority
                alt="demae-can"
              />
              出前館
            </StyledLink>
          </ExternalLinks>
        </Links>
      </Detail>

      <CopyrightWrapper>
        <Copyright name="Sopherre" />
      </CopyrightWrapper>
    </Wrapper>
  );
});

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
}));

const Detail = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(6),
  padding: theme.spacing(6),
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    padding: theme.spacing(2),
  },
}));

const OrganizationWrapper = styled("div")(({ theme }) => ({
  flexGrow: 1,
}));

const Links = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(4),
  flexShrink: 0,
  minWidth: 160,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const InnerLinks = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1.5),
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const ExternalLinks = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1.5),
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const CopyrightWrapper = styled("div")(({ theme }) => ({
  textAlign: "center",
  padding: theme.spacing(2),
}));

const BrandIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  fontSize: theme.fontSize.lg,
  marginRight: theme.spacing(1),
  height: 25,
  width: 30,
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: "inherit",
  display: "inline-flex",
  flexDirection: "row",
  alignItems: "center",
  cursor: "pointer",
}));

const UberIcon = styled(Image)(({ theme }) => ({
  height: 30,
  objectFit: "contain",
  width: "auto",
  marginRight: theme.spacing(1),
  borderRadius: Number(theme.shape.borderRadius) / 2,
  overflow: "hidden",
}));
