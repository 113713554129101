class Page {
  readonly title: string;
  readonly description: string;
  readonly pathname: string;
  readonly keywords: string[];

  constructor(page: {
    title: string;
    description: string;
    pathname: string;
    keywords: string[];
  }) {
    this.title = page.title;
    this.description = page.description;
    this.pathname = page.pathname;
    this.keywords = page.keywords;
  }
}

export const usePage = () => {
  const keywords = [
    "Aun Kitchen",
    "アウンキッチン",
    "ランチ",
    "ディナー",
    "AunKitchen",
    "メニュー",
    "テイクアウト",
    "旗の台",
    "旗の台 ランチ",
    "旗の台 ディナー",
    "旗の台駅",
    "旗の台駅 ランチ",
    "旗の台駅 ディナー",
    "長原",
    "長原 ランチ",
    "長原 ディナー",
    "長原駅",
    "長原駅 ランチ",
    "長原駅 ディナー",
  ];

  const top = new Page({
    description: "トップページ",
    keywords: [...keywords],
    pathname: "/",
    title: "Aun Kitchen",
  });

  const notifications = new Page({
    description: "お知らせ一覧ページ",
    keywords: [...keywords, "お知らせ"],
    pathname: "/notifications",
    title: "お知らせ",
  });

  const menu = new Page({
    description: "メニューページ",
    keywords: [...keywords, "メニュー"],
    pathname: "/menu",
    title: "メニュー",
  });

  const admin = new Page({
    description: "管理者ページ",
    keywords: [],
    pathname: "/admin",
    title: "管理ページ",
  });

  const signIn = new Page({
    description: "サインインページ",
    keywords: ["サインイン"],
    pathname: "/sign_in",
    title: "Sign In",
  });

  return { top, notifications, menu, admin, signIn };
};
