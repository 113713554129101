import { styled } from "@mui/material";
import { memo } from "react";
import { BrandLogo } from "../brand/logo";
import { HeaderTabs } from "../navigation/header-tabs";

export const MainHeader: React.FC = memo((props) => {
  return (
    <Wrapper>
      <LogoWrapper>
        <BrandLogo />
      </LogoWrapper>

      <ContentsWrapper>
        <HeaderTabs />
      </ContentsWrapper>

      <Actions></Actions>
    </Wrapper>
  );
});

const Wrapper = styled("div")(({ theme }) => ({
  height: 94,
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    height: 64,
  },
}));

const LogoWrapper = styled("div")(({ theme }) => ({
  lineHeight: 0,
  height: 56,
  [theme.breakpoints.down("sm")]: {
    height: 48,
  },
}));

const ContentsWrapper = styled("div")(({ theme }) => ({
  flexGrow: 1,
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
}));

const Actions = styled("div")(({ theme }) => ({}));
