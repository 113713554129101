import { usePage } from "@/hooks/ui/use-pages.hook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  styled,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useRouter } from "next/router";
import React, { Fragment, memo, useCallback, useState } from "react";

interface Item {
  label: string;
  value: string;
  pathname: string;
}

export const HeaderTabs: React.FC = memo((props) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(() => theme.breakpoints.down("sm"));
  const page = usePage();
  const router = useRouter();

  const [value, setValue] = useState(router.pathname);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const list: Item[] = [
    {
      label: "トップ",
      value: page.top.pathname,
      pathname: page.top.pathname,
    },
    {
      label: page.notifications.title,
      value: page.notifications.pathname,
      pathname: page.notifications.pathname,
    },
    {
      label: page.menu.title,
      value: page.menu.pathname,
      pathname: page.menu.pathname,
    },
    {
      label: "アクセス",
      value: "access",
      pathname: `${page.top.pathname}#access`,
    },
    {
      label: "お問い合せ",
      value: "contact",
      pathname: `${page.top.pathname}#contact`,
    },
  ];

  const handleListItemClick = useCallback(
    (item: Item) => {
      setValue(item.value);
      setAnchorEl(null);
      if (item.pathname) router.push(item.pathname);
    },
    [router]
  );

  return isSmall ? (
    <Fragment>
      <StyledIconButton
        onClick={(e) => setAnchorEl(e.currentTarget)}
        aria-label="open tabs button"
      >
        <FontAwesomeIcon icon={["fas", "bars"]} />
      </StyledIconButton>
      <Drawer
        anchor={"right"}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <StyledList>
          {list.map((item) => (
            <StyledListItemButton
              key={item.value}
              selected={item.value === value}
              onClick={() => handleListItemClick(item)}
            >
              <ListItemText primary={item.label} />
            </StyledListItemButton>
          ))}
        </StyledList>
      </Drawer>
    </Fragment>
  ) : (
    <StyledTabs value={value}>
      {list.map((item) => (
        <StyledTab
          key={item.value}
          value={item.value}
          label={item.label}
          onClick={() => handleListItemClick(item)}
        />
      ))}
    </StyledTabs>
  );
});

const StyledTabs = styled(Tabs)(({ theme }) => ({
  ".MuiTabs-indicator": {
    backgroundColor: "white !important",
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  color: "inherit !important",
  minWidth: 100,
  [theme.breakpoints.down("md")]: {
    minWidth: 70,
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: "white",
}));

const StyledList = styled(List)(({ theme }) => ({
  minWidth: 200,
}));

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  "&.Mui-selected": {
    backgroundColor: theme.color.grey[300],
  },
}));
